
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WIP from './pages/WIP';
import Search from './pages/appointment/Search';
import Appointment from './pages/appointment/Appointment';
import AppointmentList from './pages/appointmentList/AppointmentList';
import './style/main.scss';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="wip" element={ <WIP/> } />
        <Route path="search" element={ <Search/> } />
        <Route path="appointment" element={ <Appointment/> } />
        <Route path="appointmentList" element={ <AppointmentList/> } />
      </Routes>
    </div>
  );
}

export default App;