import React, {useState} from 'react';
import { notification } from '../hocs/notification';
import axios from 'axios';
import {mainUrl} from '../hocs/mainUrl';
import {
    Row,
    Col,
    Form,
    Label,
    Input,
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';

import {FaAngleDoubleRight} from "react-icons/fa";

function Login({openLogin, closeLogin_handler, ...restprops}) {
    const [screen_1, setScreen_1] = useState(true);
    const [termModal, setTermModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);
    const [mobileNo, setMobileNo] = useState();
    const [otpCode, setOtpCode] = useState();

    const checkInput = (e) => {
        let mobileNo = e.target.value;
            if((mobileNo.length) == 10){
                setMobileNo(mobileNo);
            }else{
                
            }
        // const onlyDigits = e.target.value.replace(/\D/g, "");
        // setMobileNo(onlyDigits);
      };

    const get_otp_hander = (e) => {
        e.preventDefault();

        //Get otp api Call
        if((mobileNo.length) == 10){
        let postData = { "mobile_no" : mobileNo};
        axios.post(mainUrl + 'Login/patient_otp_create', postData)
            .then(function (response) {
                setOtpCode(response.data.otp);
                let notify = notification({ message: "OTP code send in your mobile.", type: 'success' });
		        notify();
                setScreen_1(false);
            })
            .catch(function (error) {
                console.log(error); 
            });
        }else{
            alert("invalid  Mobile Number!");  
        }

    }

    const Verify_otp_hander = (e) => {
        e.preventDefault();

        //Verify otp api Call
        let postData = { "mobile_no" : mobileNo, "otp" : otpCode};
        axios.post(mainUrl + 'Login/otp_login', postData)
            .then(function (response) {
                let loginDetails = JSON.stringify(response.data);
                localStorage.setItem('loggedIn', true);
                localStorage.setItem('loginDetails', loginDetails);
                let notify = notification({ message: "Login Successfully", type: 'success' });
                notify();
                setScreen_1(true);
                closeLogin_handler();
            })
            .catch(function (error) {
                console.log(error); 
            });

    }

    const resend_click_handler = () => {
        // Get resend otp api Call
        console.log("Resend OTP code send.");
    }


    return (
        <div className='loginPage'>
            <Row className={`loginOuter m-0 p-0 ${ openLogin ? 'd-flex': 'd-none' }`} >
                
                <Col md="4" className={`borderPrimary rounded p-5 loginInner ${screen_1 ? 'd-block': 'd-none'}`}>
                    <h3 className='mb-5'>LOGIN</h3> 

                    <Form onSubmit={get_otp_hander}>
                        <FormGroup className='mb-3'>
                            <Label for="mobileNo"> Please enter your mobile number </Label>
                            <Input
                                id="mobileNo"
                                name="mobileNo"
                                placeholder="Enter Mobile Number"
                                type="text"
                                maxLength={10}
                                pattern="[0-9]*"
                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} 
                                onChange={(e) => setMobileNo(e.target.value)}
                                // onChange={(e) => checkInput(e)}
                                required
                            />
                        </FormGroup>

                        <Button
                            block
                            color="primary"
                            className='mb-3'
                        > Get OTP </Button>

                        <div className='text-end fw-bold mb-3' >
                            <span className='cursor' onClick={() => closeLogin_handler()}>
                                Skip Login <FaAngleDoubleRight />
                            </span>
                        </div>
                        <div>
                            By logging in, you agree to our <span className='text-success fw-bold cursor' 
                            onClick={() => setTermModal(true)}>Terms of Use</span> and <span className='text-success fw-bold cursor' 
                            onClick={() => setPrivacyModal(true)}>Privacy Policy</span>
                        </div>
                    </Form>
                </Col>
                <Col md="4" className={`borderPrimary rounded p-5 loginInner ${screen_1 ? 'd-none': 'd-block'}`}>
                    <h3 className='mb-5'>Verify OTP</h3> 

                    <Form onSubmit={Verify_otp_hander}>
                        <FormGroup className='mb-3'>
                            <Label for="otpCode"> Enter the OTP code sent to your mobile number </Label>
                            <Input
                                id="otpCode"
                                name="otpCode"
                                placeholder="Enter OTP code"
                                type="number"
                                value={otpCode}
                                onChange={(e)=> setOtpCode(e.target.value)}
                                required
                            />
                        </FormGroup>

                        <Button
                            block
                            color="primary"
                            className='mb-3'
                        > Verify </Button>

                        <div className='d-flex justify-content-between'>
                            <div><span className='cursor fw-bold' onClick={() => setScreen_1(true)}>Back</span></div>
                            <div>Didn’t receive OTP? <span className='text-success cursor fw-bold' onClick={() => resend_click_handler}>Resend OTP</span></div>
                        </div>
                    </Form>
                </Col>
            </Row>

            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={termModal}
            >
                <ModalHeader toggle={() => setTermModal(false)}>
                Terms of Use
                </ModalHeader>
                <ModalBody>
                <p>Welcome to doortodoctor website. The content of the app (web/mobile), pertains to doortodoctor, is the property of doortodoctor. doortodoctor, amongst other services, facilitates in finding and comparing prospective doctors and health care providers in identified locations ("service") and this service is provided only in good faith. The services of doortodoctor are essentially provided to bridge the information gap between patients and doctors. </p>

                <p>This document is an electronic record in terms of IT Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the IT Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the IT (Intermediaries guidelines) rules, 2011 that require publishing the rules and regulations, privacy policy and terms of use for access or usage of www.doortodoctor.in service. The domain name doortodoctor.in (herein after referred to as "Website") is owned by doortodoctor incorporated under the companies act, 1956 with its registered office. </p>

                <p>Your use of the app and services and tools are governed by the following terms and conditions ( "Terms of Use" ) as applicable to the app including the applicable policies which are incorporated herein by way of reference. If you transact on the app (web/mobile), you shall be subject to the policies that are applicable to the Website for such transaction. By mere use of the Application, you shall be contracting with doortodoctor and these terms and conditions including the policies constitute your binding obligations, with doortodoctor. </p>

                <p>We request you to carefully go through these terms of use ("Terms of Use") before you decide to avail the services of doortodoctor by registering with the company to avail our services, you irrevocably accept all the obligations stipulated in these terms of use and agree to abide by them. Accessing the website through any medium, including but not limited to mobile phones, smart phones and tablets, are also subjected to these terms of use. These terms of use supersede all previous oral and written terms and conditions (if any) communicated to you. By using this Website, you signify your agreement to these Terms of Use as well as the website's www.doortodoctor.in (which is hereby incorporated by reference herein). We reserve the right to modify or terminate any portion of the website or the services offered by the company for any reason, without notice and without liability to you or any third party. To make sure you are aware of any changes, please review these terms of use periodically. The website should not be used in cases of medical emergencies and doortodoctor does not, and should not be considered in any form to be a substitute for a doctor or a hospital. </p>

                <p>We encourage you to independently verify any information you see on the website with respect to a doctor or healthcare provider that you seek to make an appointment registration: If you wish to schedule an appointment with a doctor or conduct a transaction on the app, you will have to register on the app and become a registered user ("doortodoctor User") prior to the completion of any transaction on the doortodoctor. To register onto the website, a user will have to provide information such as his/her name, email address and phone number and create a username and password. You understand that doortodoctor may introduce a fee for registration on the website ("Registration Fees"). Registration Fees, if and when introduced shall be non-refundable. DoortoDoctor may introduce special features which may be made available to doortodoctor users who pay registration fees. Registration is only a one-time process and if the doortodoctor user has been previously registered, she/he shall login/sign into his/her account using the same credentials as provided during the registration process. We request you to please safeguard your password to your account on the app and make sure that others do not have access to it. </p>

                <p>It is your responsibility to keep your account information safe and current. A doortodoctor user may wish to schedule an appointment through his/her account for his/her family members and friends. While a doortodoctor user will be able to do this, please note that such doortodoctor user will be solely responsible for any activity that is undertaken through his/her account on behalf of his/her family members and friends. </p>

                <h5>INFORMATION UPLOADED BY YOU ON THE APP: </h5>
                <p>You understand that you may have the option of uploading your health / medical information on the Website in order to enable your doctor access your previous medical history. While doortodoctor takes utmost care with respect to the security of the information you decide to upload, you understand that any information that you disclose on the website is at your own risk. By uploading / sharing / disclosing your medical information on the App, you hereby give your consent to app to store such health / medical information on DoortoDoctor's servers. doortodoctor will retain such medical / health information you upload on the app for as long as it is needed to full fill the service you seek to avail on the app. If you delete your account, doortodoctor will delete such medical / health information. </p>

                <h6>But please note: </h6>

                <p>There might be some latency in deleting this information from our servers and backup storage. </p>
                <p>We may retain this information if necessary to comply with our legal obligations, resolve disputes, or enforce our agreements. </p>
                <h5>SHARING OF INFORMATION UPLOADED BY YOU ON THE APP</h5>
                <p>Unless you specifically consent to share information, medical or otherwise, with either doctors or third party service providers, app does not share such information that you upload on the app. While doortodoctor takes utmost care with respect to the security of the information you decide to share, you understand that any information that you disclose on the website is at your own risk. In the event you do not trust doortodoctor with such information, please do not share such information on the app, while doortodoctor endeavors to protect any information uploaded or shared by you on the website, you understand that doortodoctor shall not be held liable or responsible for the loss or damage to such information on the app. It is your responsibility to ensure that you have all your information, medical or otherwise, stored on your hard drive. You hereby agree not to use the website as an "external storage" to store your medical / health records. </p>

                <h5>COLLECTION OF CONSULTATION FEES</h5>
                <p>You understand that doortodoctor provides various services with respect to the System. While some of these services are provided for free, there are certain services for which costs will be incurred by you, if you decide to avail such services ("paid services"). In that case, doortodoctor may provide billing services on behalf of the doctor or health care professional. Should you wish to avail those paid services, you acknowledge that doortodoctor will collect the payment for such paid services for and on behalf of the doctor or healthcare professional providing such clinical services. You acknowledge and confirm that doortodoctor shall not be liable for the treatment or be treated as the health care provider on account of such collection of the payments or for provision of such paid services, for any reason what so ever</p>

                <h5>CLINICAL SUPPORT INFORMATION</h5>
                <p>We may provide information to assist you in clinical decision-making. This may include information and reminders concerning drug interactions, allergies, dosages, as well as general health-care related information and resources. The information and materials available through the app are for informational and educational purposes only and are not intended to constitute professional advice, diagnosis or treatment, or to substitute for your professional judgment. </p>

                <p>Information that may be placed on the app by third parties are beyond our control. We are not responsible for the accuracy or completeness of information available from or through our app. You assume full risk and responsibility for the use of information you obtain from or through this app, and you agree that doortodoctor is not responsible or liable for any claim, loss, or liability arising from the use of the information. We do not recommend or endorse any provider of healthcare or health-related products, items or services, and the appearance of materials on the app relating to any such products, items or services is not an endorsement or recommendation of them. You agree to review the definitions, functionality, and limitations of the services, and to make an independent determination of their suitability for your use. </p>
                <h5>SELECTING YOUR DOCTOR OR HEALTHCARE PROVIDER ON THE APP</h5>
                <p>The App allows you to select and schedule appointments with doctors and health care providers registered with the website and to whom certain specific terms doortodoctor. While choosing your doctor or healthcare provider on the App, you understand and accept that: </p>

                <p>You are ultimately responsible for choosing your own doctor or healthcare provider and doortodoctor shall not be held responsible or liable for the selection of such doctor or healthcare provider. </p>
                <p>doortodoctor will provide you with lists and/or profile previews of doctors and health care providers who may be suitable to deliver the health care that you are seeking based on information that you provide to doortodoctor (such as area of specialty and geographical location). For certain specific services, doortodoctor may choose a doctor or healthcare provider on your behalf based on the nature of services you request and on the doctor or healthcare professional available to provide you with the clinical services for the same. </p>
                <p>Please note that doortodoctor</p>
                <p>Does not recommend or endorse any doctors or health care providers mentioned on the app.</p>
                <p>Does not make any representations or warranties with respect to these doctors or health care providers or the quality of the healthcare services they may provide. doortodoctor shall not be liable for any reason whatsoever for the services provided by the health care professional or doctor, and we bear no liability for the consequences to you, of your use of the system or services. </p>
                <p>Information regarding doctors and health care providers and practice information found on the app is intended for general reference purposes only. Such information found on the app is mainly self-reported by the doctor or the health care provider. Such information often changes frequently and may become out of date or inaccurate. Neither the app nor doortodoctor provides any advice or qualification certification about any particular doctor or healthcare provider. You are encouraged to independently verify any such information you see on the website with respect to a doctor or healthcare provider that you seek to make an appointment with. </p>

                <h5>NO DOCTOR - PATIENT RELATIONSHIP</h5>
                <p>Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, "Information") that may be available on the app (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between doortodoctor and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified practitioner. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the website. We do not in any way endorse any individual described herein. In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information. </p>

                <h5>APPLICABLE LAW</h5>
                <p>The interpretation of this agreement and the resolution of any disputes arising under these terms of use shall be governed by the laws of India. </p>

                <h5>REFUND & CANCELLATION POLICY </h5>
                <p>In case of cancellation or non-confirmation of the appointment by doortodoctor due to any reasons, three options are available to the consumer: </p>

                <p>He/She may ask for rescheduling the appointment with the specialist doctor. </p>
                <p>He/She may claim a refund of the advance payment t&c apply. </p>
                <p>In case the consumer misses or cancels the appointment, he shall not be entitled to any refund. </p>
                <p>In case the consumer's credit card/debit card/payment account has been accidentally overcharged, please notify doortodoctor of the same at the earliest. In case of over-charging, the consumer has the following options: He may claim a refund of the outstanding amount. In such a scenario, doortodoctor shall make all endeavors to refund the amount within 7-14 working days. The outstanding amount may also be credited to the account of the consumer so as to be adjusted in future consultations of himself or of any other person. </p>
                <p>The refund shall be made by e-banking or by demand draft or by other such mode other than cash, depending upon the suitability of both, doortodoctor and the consumer. </p>
                <p>For claiming refund, the consumer should necessarily have the valid invoice of the investigations, so as to be able to get the refund. </p>
                <h5>ADVICE OF COUNSEL EACH PARTY ACKNOWLEDGES</h5>
                <p>Having fully read these terms of use in its entirety, </p>
                <p>Having had full opportunity to study and review these terms of use; </p>
                <p>Having been advised that counsel for us has acted solely on our behalf in connection with the negotiation, preparation, and execution of these terms of use. </p>
                <p>Having been advised that all parties have the right to consult and should consult independent counsel respecting their rights and duties under these Terms of Use. </p>
                <p>Having had access to all such information as has been requested. You have read these terms of use and agree to all of the provisions contained above. </p>
                <h5>SMS MESSAGES RECEIVED FROM THE APP</h5>
                <p>You understand that once you register as a doortodoctor user on the app, you will receive SMS messages from doortodoctor on your registered mobile number. These messages could relate to your registration, transactions that you carry out through the app and promotions that are undertaken by doortodoctor. Please note that doortodoctor will send these SMS messages only to the registered mobile number or such other number that you may designate for any particular transaction. It is your responsibility to ensure that you provide the correct number for the transaction you wish to enter. Further, doortodoctor may also send notifications and reminders to you with respect to appointments scheduled by you for the services. Please note that while doortodoctor to provide these notifications and reminders to you promptly, doortodoctor does not provide any guarantee and shall not be held liable or responsible for the failure to send such notifications or reminders to you. It is your responsibility to ensure that you attend any appointments that you may schedule with a doctor or a health care provider through the website. </p>

                <h5>AVAILABILITY, APPOINTMENT, RESCHEDULING, FREE REVIEW AND/OR CANCELLATION</h5>
                <p>E-consultations shall be available for family physician & multi specialties E-consultation from a specific doctor/physician shall be available subject to his/her availability/willingness/discretion. Confirmation of your appointment with a specific doctor/physician, as per his/her availability, shall be sent to you via SMS and/or E-mail. doortodoctor reserves the right to reschedule or cancel an appointment without any prior notice. The time provided for consultation to you is indicative and actual consultation time may change depending on the consulting doctor's discretion. Your consulted physician reserves the right to provide post consultation free review consultations can be booked, rescheduled only within the stipulate time limits mentioned below: </p>
                <p>Table: Cancellation & Rescheduling deadlines</p>
                <p>no monetary refunds will be given for any reason, though reschedule of appointment may be offered if the doctor is not available on the day of appointment. </p>


                    <small className='text-danger fw-bold'>Any dispute subject to Cuttack, Odisha judiciary only.</small>
                </ModalBody>
                
            </Modal>
            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={privacyModal}
            >
                <ModalHeader toggle={() => setPrivacyModal(false)}>
                    Privacy Policy
                </ModalHeader>
                <ModalBody>
                    <p>By using the services, accessing the website, www.doortodoctor.in (“Website”), registering yourself on the Website and/or by otherwise giving DoortoDoctor any of your information/Personal Data, it will be deemed that you have carefully read, understood and agreed to the practices and policies outlined in this Privacy Policy and agree to be bound by the Privacy Policy. DoortoDoctor reserves the right to change, modify, add or delete portions of the terms of this Privacy Policy, at its sole discretion, at any time, without any prior or subsequent intimation. It is your duty to update yourself about any change, modification, addition or deletion in any portion of this Privacy Policy made by DoortoDoctor. If you do not agree with any change, modification, addition or deletion made in any portion of this Privacy Policy, at any point in time, do not use any of the services available on the Website or provide us any of your information.</p>
                    <p>If you use the services or access the Website on behalf of someone else or an entity (such as your employer), you represent that you are duly authorised by such individual or entity to accept and consent, on behalf of such person, to this privacy policy and the collection, use and disclosure of information of such individual or entity by DoortoDoctor.</p>
                    <p>We are committed to protecting your privacy. Authorized representatives of DoortoDoctor use any Personal Data received from you or a registered medical practitioner authorized by you strictly on a need-to-know basis. We constantly review our systems and data to secure your Personal Data. In case of a breach, we will investigate such actions with a view to prosecuting and/or initiating civil proceedings to recover damages against those responsible.</p>
                    <p>Any User who does not consent to this Privacy Policy or any part thereof is required to leave the Website immediately. Should you disagree with this Privacy Policy and still continue to access the Website, DoortoDoctor disclaims all liabilities arising from your use of the Website in accordance with this Privacy Policy.</p>
                    <h5>Applicability</h5>
                    <p>This Privacy Policy applies to all Users who access the Website and are, therefore, required to read and understand the Privacy Policy before submitting any personal data.</p>
                    <p>Personal Data shall include the information which identifies the User, including name, identification number, email address, age, phone number, password, health record (including the name of the doctor) or any financial information, provided to DoortoDoctor through our Website, in email, text, forms and other electronic communication received through or in connection with our Website.</p>
                    <p>This Privacy Policy applies to Website and services that are owned and operated by DoortoDoctor. DoortoDoctor does not exercise control over the third party websites linked to the Website. Third party websites may place their own cookies or other files on the User’s computer, collect data or solicit personal information from the User, for which DoortoDoctor is not responsible or liable. Accordingly, DoortoDoctor does not make any representation concerning the privacy practices or policies of such third parties or terms of use of such websites, nor does DoortoDoctor guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. The inclusion or exclusion of third-party links does not imply any endorsement by DoortoDoctor of the website, the website provider, or the information on the website. DoortoDoctor encourages the User to read the privacy policy and terms of use of any third-party website the User has been directed/redirected/linked to.</p>
                    <h5>Right to Erasure/Modification</h5>
                    <p>DoortoDoctor recognizes your right to have your Personal Data deleted in case you withdraw consent, or the Personal Data is no longer necessary for the purpose for which it was originally collected and processed. For such erasure, Users shall have to make a request to DoortoDoctor in writing.</p>
                    <p>If any part of the Personal Data provided by you is wrong, you can request us to modify or delete the same. DoortoDoctor will take all reasonable measures to ensure that the information is modified and used for rendering services to you and as otherwise in compliance with law. When editing or deleting personal information, we may ask you to verify your identity before we can act on your request.</p>
                    <p>DoortoDoctor may at its discretion reject any request that is contrary to law or requires unreasonable technical efforts. We do not assure that we will delete all residual copies and archives made by any third party without our knowledge and consent.</p>
                    <h5>Personal Data we collect</h5>
                    <p>DoortoDoctor may collect your Personal Data including, but not limited to:</p>
                    <ul>
                        <li>Information you provide to us while placing an order for a product on our Website, without registering/signing up. DoortoDoctor takes no responsibility to validate the information provided by the guest, except as otherwise required under any law, regulation or an order of a competent authority.</li>
                        <li>Information you provide to us while signing up / creating an account on our Website. To create an account, a User is required to provide personal data as may be required under the Application / Registration Form. Other information requested on the registration page, including the ability to receive promotional offers from DoortoDoctor, is optional. DoortoDoctor may, in future, include other optional requests for information from the User to help DoortoDoctor customize the Website to deliver personalized information to the User. Without a User’s consent, DoortoDoctor will not share, rent or sell any personal information to third parties for any purpose other than what is set out in this Privacy Policy.</li>
                        <li>Records of telephone calls, emails and other electronic communication received and made for raising queries, making enquiries, placing orders, offering feedback or other purposes for the purpose of rendering services effectively and efficiently.</li>
                        <li>Data/Information about how you and your devices interact with this Website. This could include features you use, the web pages you visit and the search terms you enter. This could also include information about your devices and the network you use to connect to our services, including IP addresses, device identifiers and information about the performance of the services you use on the Website and any problems you experience with them.</li>
                    </ul>
                    <h5>Use of Personal Data</h5>
                    <p>We generally use your Personal Data for the following purposes:</p>
                    <ul>
                        <li>DoortoDoctor may, if you so choose, send direct mailers to you at the address given by you. You have the option to ‘opt-out’ of this direct mailer by way of links provided at the bottom of each mailer. We respect your privacy and, to the extent that you choose not to receive such mailers, we will take all reasonable steps to remove you from the mailing list. Should you evidence any violation of our terms and policies, please write to us or call us on our given number and report such incidents.</li>
                        <li>Your Personal Data may be shared with third parties who have need or authority to receive such information, if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to (i) comply with a lawful order of an authority competent to receive such information, (ii) comply with any order of a court, (iii) detect, prevent, or otherwise address fraud, data security or technical issues, (iv) protect against harm to the rights, property or safety of DoortoDoctor, our users or the public as required, permitted or mandated by law.</li>
                        <li>An DoortoDoctor Representative may request you, either by email, phone or letter/courier, to provide certain Personal Data (such as name, address, name of your doctor, products that are consumed, etc.) and such other information that may be required from time to time. Should DoortoDoctor use such Personal Data for analysis of your lifestyle through a certified officer, you authorize us to share the same with your doctor for the limited purpose of his understanding and validation. However, such Personal Data and records shall be the exclusive property of DoortoDoctor, unless you opt to purchase the same for a consideration payable to DoortoDoctor.</li>
                        <li>DoortoDoctor may use Personal Data, on the condition of anonymity of the User, for the purpose of research, statistical analysis and business intelligence and may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates.</li>
                        <li>DoortoDoctor shall not make any unsolicited calls or otherwise market any products or services, except for in relation to the purpose for which such information has been provided or for receiving feedback or addressing complaints raised by a User. Users shall not disclose their Personal Data to any third parties not authorized by DoortoDoctor and shall verify the identity of such personnel who seek to collect personal data. DoortoDoctor will communicate with Users through email and notices posted on the Website or through other means available, including text and other forms of messaging. The Users can change their e-mail and contact preferences at any time by logging into their account or by calling DoortoDoctor customer support or such other mode as may be prescribed.</li>
                        <li>DoortoDoctor may also disclose or transfer Personal Data to a third party as part of a reorganization or sale of assets of an DoortoDoctor corporation, division or company. Any third party to which DoortoDoctor transfers or sells its assets will have the right to continue to use such Personal Data.</li>
                        <li>To the extent necessary to provide services on this Website, DoortoDoctor may provide Personal Information to third party contractors who work on behalf of or with DoortoDoctor to provide Users with such services, to help DoortoDoctor communicate with Users or to maintain the Website. Generally, these contractors do not have any independent right to share this information. However, certain contractors who provide services on the Website, including those providing online communications services, will have a right to use and disclose the personal information collected in connection with the provision of these services in accordance with their own privacy policies.</li>
                        <li>DoortoDoctor may also use different technologies to process your Personal Data for the above mentioned purposes including automated systems that analyse your usage to provide you with customized tailor-made search results and content relevant to you.</li>
                    </ul>
                    <h5>Security</h5>
                    <p>DoortoDoctor has taken all reasonable precautions in accordance with industry standard to treat Personal Data as confidential. DoortoDoctor has implemented security infrastructure to protect from unauthorized access, improper use, disclosure, modification and unlawful destruction or accidental loss of Personal Data.</p>
                    <p>All DoortoDoctor employees and data processors, who have access to and are associated with the processing of personal data, are obliged to respect the confidentiality of every User’s Personal Data.</p>
                    <h5>Amendment</h5>
                    <p>DoortoDoctor may update or amend this Privacy Policy at any time, with or without advance notice. If there are significant changes in the way DoortoDoctor treats sensitive personal data, DoortoDoctor will display a notice on the Website or send Users an email. DoortoDoctor‘s current Privacy Policy applies to all information that DoortoDoctor has about Users and their account.</p>
                    <p>Notwithstanding the above, DoortoDoctor shall not be required to notify the Users of any changes made to the Privacy Policy. Should you have any concern or reject the changes in the Privacy Policy. you can refuse to accept the amendments and opt for erasure of your Personal Information by writing to us.</p>
                    <p className='text-primary'>If you have any compliant or grievance with respect to this Website or this Privacy Policy, please write to us at <b>doortodoctor@gmail.com</b></p>
                    <small className='text-danger fw-bold'>Any dispute subject to Cuttack, Odisha judiciary only.</small>
                </ModalBody>
                
            </Modal>
        </div>
    )
}

export default Login;