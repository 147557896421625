import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingSpinner = () => {

    return(
        <div className='loadingOuter'>
            <div class="lds-heart"><div></div></div>
        </div>
    )

}

export default LoadingSpinner;