import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import { mainUrl } from '../../component/hocs/mainUrl';
import Slider from './../../component/utility/Slider';
import DocDetail from './../../component/utility/appointment/DocDetail';
import PickTimeSlot from './../../component/utility/appointment/PickTimeSlot';
import Footer from '../../component/layout/Footer';

import {
    Row,
    Col,

} from 'reactstrap';

const Appointment = ({ ...props}) => {
    const {state} = useLocation();
    const [docDetail, setDocDetail] = useState();
    const [docSlot, setDocSlot] = useState();
    
    useEffect(() => {
        console.log(state)
        const id = sessionStorage.getItem('doc_id');
        let postData = {"doctor_id" : id}
        
        axios.post(mainUrl + 'Doctor/doctor_book_view', postData)
            .then(function (response) {
                setDocDetail(response.data.doctor_view);
                setDocSlot(response.data.doctor_slot);
            })
            .catch(function (error) {
                console.log(error); 
            });
      },[]);
    
    return(
        <div>

            <Slider />
            
            <div className='borderBottom'></div>

            <Row className='p-2'>
                <Col md="7">
                    <DocDetail data={docDetail} />
                </Col>
                
                <Col md="5">
                    
                {/* {hours >= 6 && hours <= 11 && */}
                    <PickTimeSlot data={docSlot} docDtl={docDetail} rebook={state} />
 {/* }{
    <center>
    <b style={{color: "#dc3549"}}>Booking Time Should Be Open 6AM - 12AM.</b>
    </center>
 } */}
                </Col>

            </Row>
            <Footer />
        </div>
    )
}

export default Appointment;