import React from 'react';
import Footer from './../component/layout/Footer';
import Banner from './../component/utility/Banner';
import DepartmentBox from './../component/utility/home/DepartmentBox';

import d1 from './../assets/img/d1.png';
import d2 from './../assets/img/d2.png';
import d3 from './../assets/img/d3.png';
import d4 from './../assets/img/d4.png';

import dep_1 from './../assets/img/icon/dep_1.png';
import dep_2 from './../assets/img/icon/dep_2.png';
import dep_3 from './../assets/img/icon/dep_3.png';
import dep_4 from './../assets/img/icon/dep_4.png';
import dep_5 from './../assets/img/icon/dep_5.png';
import dep_6 from './../assets/img/icon/dep_6.png';
import dep_7 from './../assets/img/icon/dep_7.png';
import dep_8 from './../assets/img/icon/dep_8.png';

import {
    Row,
    Col,
    Card,
    CardImg,
    CardBody,

} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    const gotoSearchPage = (id) => {
        console.log("aaya")
        sessionStorage.setItem('department_id', id);
        navigate('/search');
    }

    return (
        <div>

            <Banner />
            
            <div className='borderBottom'></div>

            <Row className="">
                <Col xs="12 p-5 text-center">
                   <h2 className='text-primary'>Popular department</h2> 
                </Col>
            </Row>
            <div className='borderBottom'></div>

            <Row className='p-3 d-flex justify-content-around'>

                <DepartmentBox goto={()=>gotoSearchPage(10)} name="Cardiology" imgsrc={dep_1} />
                <DepartmentBox goto={()=>gotoSearchPage(1)} name="Medicine" imgsrc={dep_2} />
                <DepartmentBox goto={()=>gotoSearchPage(12)} name="Ophthalmology" imgsrc={dep_3} />
                <DepartmentBox goto={()=>gotoSearchPage(0)} name="Dental" imgsrc={dep_4} />
                <DepartmentBox goto={()=>gotoSearchPage(8)} name="Gynecology" imgsrc={dep_5} />
                <DepartmentBox goto={()=>gotoSearchPage(14)} name="Neurology" imgsrc={dep_6} />
                <DepartmentBox goto={()=>gotoSearchPage(11)} name="ENT" imgsrc={dep_7} />
                <DepartmentBox goto={()=>gotoSearchPage(21)} name="Nephrology" imgsrc={dep_8} />

            </Row>

            <div className='borderBottom'></div>
            {/* 
            <Row className="">
                <Col xs="12 p-5 text-center">
                   <h2 className='text-primary'>Our specialish</h2> 
                    <div>
                    Green above he cattle god saw day multiply under fill in the cattle fowl a all, living, 
                    </div>
                    <div>
                    tree word link available in the service for subdue fruit.
                   </div>
                </Col>
            </Row>

            <div className='borderBottom'></div>

            <Row className='p-3'>
                <Col md='3'>
                    <Card>
                        <CardImg
                        alt="Card image cap"
                        src={d1}
                        top
                        width="100%"
                        />
                        <CardBody className='text-center'>
                            <h5>ETHEL DAVIS</h5>
                            <small>SR. FACULTY DATA SCIENCE</small>    
                        </CardBody>
                    </Card>    
                </Col>
                <Col md='3'>
                    <Card>
                        <CardImg
                        alt="Card image cap"
                        src={d2}
                        top
                        width="100%"
                        />
                        <CardBody className='text-center'>
                            <h5>DAND MORIES</h5>
                            <small> SR. FACULTY PLASTIC SURGERY</small>    
                        </CardBody>
                    </Card>    
                </Col>
                <Col md='3'>
                    <Card>
                        <CardImg
                        alt="Card image cap"
                        src={d3}
                        top
                        width="100%"
                        />
                        <CardBody className='text-center'>
                            <h5>ALIGN BOARD</h5>
                            <small> SR. FACULTY DATA SCIENCE</small>    
                        </CardBody>
                    </Card>    
                </Col>
                <Col md='3'>
                    <Card>
                        <CardImg
                        alt="Card image cap"
                        src={d4}
                        top
                        width="100%"
                        />
                        <CardBody className='text-center'>
                            <h5>JESON LIMIT</h5>
                            <small> SR. FACULTY PLASTIC SURGERY</small>    
                        </CardBody>
                    </Card>    
                </Col>
            </Row>
            */}
            <Footer />



        </div>
    )
}

export default Home;