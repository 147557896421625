import React, {useState} from 'react';

import {
    Col,
} from 'reactstrap';

function DepartmentBox({name, imgsrc, goto, ...restprops}) {
    const [isHover, setIsHover] = useState(false);

    return (
        <Col onClick={goto} md="3" className={`rounded p-3 mb-1 shadow-sm cursor ${isHover ? 'departmentBoxHover':''} `} 
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            >
            <div className='text-center mb-3'><img width="80px" src={imgsrc} alt="logo" /> </div>
            <div className='text-center text-uppercase'><h5>{name}</h5></div>
        </Col>
    )
}    

export default DepartmentBox;