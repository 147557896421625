import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,

} from 'reactstrap';


const ListCard = ({data, ...props}) => {
    
    const navigate = useNavigate();
    
    const rebooking_handler = () => {
        console.log("re-booking");

        sessionStorage.setItem('doc_id', data.doctor_master_id);
        sessionStorage.setItem('department_id', data.deperment_id);
        
        navigate('/appointment', { state: { rebooking: true, doc_id: data.doctor_master_id, booking_id: data.booking_id, } });
    }

    return (
        <Col xs="4">
            <Card className='mt-2' color={(data.cancel_status === "0") ? "primary" : "danger"} outline >
                <CardHeader className='d-flex justify-content-between'>
                    Booking Date: {data?.booking_date} 
                    {   data.cancel_status === "1" && 
                        <Button className='text-white' size="sm" color='success' onClick={rebooking_handler}> Re-booking </Button>
                    }
                </CardHeader>
                <CardBody>
                    <h4 color='primary-text'>{data?.name}</h4>
                    <p className='m-0'>{data?.deparment_name}</p>
                    
                    <div className="borderDashed mt-1 mb-1"></div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Appointment Number</div>
                        <div>{data?.booking_id}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Appointment Status</div>
                        <div>{ (data?.cancel_status === "0") ? <div className='text-success'>Confirm</div> : <div className='text-danger'>Canceled</div>}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Date of Appointment</div>
                        <div>{data?.booking_date}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Timing</div>
                        <div>{data?.slot}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Appointment Fee</div>
                        <div>&#8377; {data?.amount}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Fee Status</div>
                        <div className='text-success'>Paid</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold'>Clinic Address</div>
                        <div className='text-end'>
                            <p className='m-0'>{data?.address}</p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>

    )

}
export default ListCard;