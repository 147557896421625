import React from 'react';
import { Link } from "react-router-dom";
import { FaHeartbeat } from "react-icons/fa";
import {
    Row,
    Col,
    Button,

} from 'reactstrap';


function Banner() {
    return (
        <Row className="banner">
                <Col className='p-5 mt-4' sm="6" md="5">
                <div>Caring for better life</div>
                <h1>Leading the way in medical excellence</h1>
                
                <div className='text-center mt-3'>
                    <Link to="/search">
                        <Button color="primary"><FaHeartbeat /> TAKE APPOINTMENT</Button>
                    </Link> 
                </div>    
            </Col>
        </Row>
    )

}
export default Banner;