import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { mainUrl } from '../../component/hocs/mainUrl';
import Slider from '../../component/utility/Slider';
import ListCard from '../../component/utility/appointment/ListCard';
import Footer from '../../component/layout/Footer';
import LoadingSpinner from '../../component/utility/LoadingSpinner';

import {
    Row,
    Col,
    Alert

} from 'reactstrap';

const AppointmentList = () => {
    const [loading ,setLoading] = useState(false);
    const [a_list ,setA_list] = useState(null);

    useEffect(()=>{
        setLoading(true);
        let loginDetails = JSON.parse(localStorage.getItem('loginDetails'));
        console.log(loginDetails);
        let postData = {"patient_id" : loginDetails.patient_id};
        axios.post(mainUrl + 'Patient/booked_list', postData)
        .then(function (response) {
            // console.log(response);
            if(response.data !== null){
                let appoint_list = response.data.map(row => {
                    return(
                        <ListCard data={row} />
                    )
                })
    
                setA_list(appoint_list);
            }else{
                setA_list(null);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error); 
            setLoading(false);
        });
    },[])
    return(
        <div>

            <Slider />
            
            <div className='borderBottom'></div>

            <Row className='p-2'>
                {
                    a_list !== null ? a_list :
                    <Col> 
                        <Alert color="danger" >
                            No Appointment List Found!!!
                        </Alert>
                    </Col>
                
                }
            </Row>

            <Footer />
            {loading && 
                <LoadingSpinner />
            }
        </div>
    )
}

export default AppointmentList;