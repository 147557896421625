import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../../hocs/notification';
import { todayDate, maxDate } from '../../hocs/formatDate';
import axios from 'axios';
import { mainUrl } from '../../hocs/mainUrl';
import TimeSlotPicker from './TimeSlotPicker';
import Login from '../../layout/Login';
import LoadingSpinner from '../LoadingSpinner';

import {
    Row, Col, Card, CardHeader, CardBody,
    Input, Label, Alert, Button, Form, FormGroup,
    ModalBody, ModalHeader, Modal, Spinner 

} from 'reactstrap';

const PickTimeSlot = ({data, docDtl, rebook, ...props}) => {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [loginShow, setLoginShow] = useState(false);
    const [timeData, setTimeData] = useState(null);
    const [selectedTime, setSelectedTime] = useState("1");
    const [selectedDate, setSelectedDate] = useState(null);
    const [patient_name, setPatient_name] = useState(null);
    const [patient_mobile, setPatient_mobile] = useState(null);
    const [patient_modal, setPatient_modal] = useState(false);
    const [loginDetails, setLoginDetails] = useState(null);
    const [slotLoading, setSlotLoading] = useState(false);
    const date = new Date();
    const hours = date.getHours();
    
    useEffect(() => {
        console.log(rebook);
        let loginDetails = JSON.parse(localStorage.getItem('loginDetails'));
        setLoginDetails(loginDetails);
        setPatient_mobile(loginDetails?.mobile_number);
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    },[])

    const loadScript = (url) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
    
            script.src = url;
            script.onload = () => {
                resolve(true);
            }

            script.onerror = () => {
                resolve(false);
            }

            document.body.appendChild(script);
        })

    }

    useEffect(() => {
        if(localStorage.getItem('loggedIn') !== 'true'){
            setLoginShow(true);
        }

        //setTimeData(data);
    }, [data])

    const selectTime = (t) => {
        setSelectedTime(t);
    }

    const book_appointment = (e) => {
        if((patient_mobile.length) == 10){
        e.preventDefault();
        setLoading(true);
        if(rebook.rebooking){
            //re-booking api call
            let postData = { "booking_id": rebook.booking_id, 
                "slot_id": selectedTime, 
                "doctor_id": rebook.doc_id, 
                "date":selectedDate,
                "patient_name": patient_name,
                "patient_phone": patient_mobile    
            }
            axios.post(mainUrl + 'Admin/rebooking', postData)
                .then(function (response) {
                    
                    setPatient_modal(false);
                    let notify = notification({ message: "Your appointment Re-booked successfully.", type: 'success' });
                    notify();
                    navigate('/appointmentList');
                    setLoading(false);

                })
                .catch(function (error) {
                    console.log(error); 
                    setLoading(false);
                });

        }else{
            let d = new Date (selectedDate);
            let day = d.getDay();
            let postData = {
                "doctor_id" : sessionStorage.getItem('doc_id'),
                "date" : selectedDate,
                "day_id" : day,
                "slot_id" : selectedTime,
                "user_id" : loginDetails?.patient_id,
                "deparment_id" : sessionStorage.getItem('department_id'),
                "patient_name": patient_name,
                "patient_phone": patient_mobile
            }
            
            axios.post(mainUrl + 'Booking/slot_booking', postData)
                .then(function (response) {
                    
                    let booking_id = response.data[0].booking_id;
                    let postData2 = {"doctor_id" : sessionStorage.getItem('doc_id'),
                    "slot_id" : selectedTime,
                    "booking_id": booking_id,
                    "amount": docDtl.fee,
                    "user_id" : loginDetails?.patient_id,
                    "type" : "booking_slot"}
    
                    axios.post(mainUrl + 'Payment/generate_order', postData2)
                    .then(function (res) {
                        console.log(res.data);
                        let order_id = res.data.id;
                        const options = {
                            "key": "rzp_test_rlnigPieg5fETU", // Enter the Key ID generated from the Dashboard
                            "amount": docDtl.fee, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                            "currency": "INR",
                            "name": "Door to Doctor",
                            "description": "Welcome",
                            "image": "https://example.com/your_logo",
                            "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                            "handler": function (response){
                                
                                update_paymentDetails(response);
                            },
                            "prefill": {
                                "name": patient_name,
                                "email": "",
                                "contact": patient_mobile
                            },
                            "modal": {
                                "ondismiss": function(){
                                    let notify = notification({ message: "Payment Failed! But appointment book successfully.", type: 'warn' });
                                    notify();
                                    navigate('/appointmentList/');
                                    setLoading(false);
                                }
                            }
                        }
        
                        //display the Razor pay window
                        const paymentObject = new window.Razorpay(options);
            
                        paymentObject.open();
                    })
                    .catch(function (error) {
                        console.log(error); 
                        setLoading(false);
                    });
     
                })
                .catch(function (error) {
                    console.log(error); 
                    setLoading(false);
                });
        }
    }else{
        alert("Invalid Mobile Number!");  
    }
    }

    const update_paymentDetails = (res) => {
        console.log(res);
        let postData = {"razorpay_order_id": res.razorpay_order_id , "razorpay_payment_id": res.razorpay_payment_id}
        axios.post(mainUrl + 'Payment/payment_details_update', postData)
            .then(function (response) {
                //console.log(response.data);
                setPatient_modal(false);
                let notify = notification({ message: "Payment Success and appointment book successfully.", type: 'success' });
                notify();
                navigate('/appointmentList/');
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error); 
                setLoading(false);
            });
    }

    const showPatientModal_handler = () => {
        if(localStorage.getItem('loggedIn') !== 'true'){
            setLoginShow(true);
            let notify = notification({ message: "Login required!", type: 'warn' });
		    notify();
        }else{
            if(selectedTime !== null && selectedDate !== null){
                //console.log(selectedDate);
                //console.log(selectedTime);
                setPatient_modal(true);

            }else{
                let notify = notification({ message: "Please Select Date and Time.", type: 'warn' });
                notify(); 
            }
        }
    }

    const date_change_handler = (e) => {
        setSlotLoading(true);
        
        setSelectedDate(e.target.value);
        let d = new Date (e.target.value);
        let day = d.getDay();
        setTimeData(null);
        // if(d == date && ){
        let postData = {"doctor_id": sessionStorage.getItem('doc_id'), "date": e.target.value, "day_id":day}
        axios.post(mainUrl + 'Doctor/slot_already_booked', postData)
            .then(function (response) {
                console.log(response.data);
                if(response.data.doctor_slot === "not able"){
                    setTimeData(null);
                    setSelectedTime(null);
                    setSlotLoading(false);
                }else{
                    setTimeData(response.data.doctor_slot);
                    setSelectedTime(null);
                    setSlotLoading(false);
                }
                
            })
            .catch(function (error) {
                setSlotLoading(false);
                console.log(error); 
            });
        // }else{
        //     setTimeData(response.data.doctor_slot);
        //     setSelectedTime(null);
        //     setSlotLoading(false); 
        // }
    } 


        return (
            <>
                <Card className='mt-2'>
                    <CardHeader>
                        <h5 className='text-center text-primary m-0'>PICK A TIME SLOT</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs='12' className='mb-2'>
                                
                                <p className='m-0'><b>Address:</b> </p>
                                <p className='m-0'>{docDtl?.address}</p>
                                <p className='m-0'></p>
                                <p className='m-0'></p> 
                            </Col>
                            <Col xs="12">
                                <Alert color="warning">
                                After you have submitted the appointment request, we might call to confirm the preferred appointment slot.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12 mb-2'>
                                <Label for="date"> Select Date </Label>
                                <Input
                                    id="date"
                                    name="date"
                                    placeholder="date placeholder"
                                    type="date"
                                    min={todayDate()}
                                    max={maxDate()}
                                    onChange={(e) => {
                                        date_change_handler(e);
                                    }}
                                />
                            </Col>
                            <Col xs='12 mb-2'>
                                <Label for="date"> Select Available Time Slot </Label>

                                { !slotLoading ?
                                    <TimeSlotPicker data={timeData} select={selectTime} />
                                    :
                                    <div className='text-center'>
                                        <Spinner color="primary">Loading...</Spinner>
                                    </div>
                                }
                            </Col>
                            <Col xs='12 mb-2'>
                                <Button size="sm" color="primary" onClick={ showPatientModal_handler} block>Submit</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Modal
                    size="md"
                    centered
                    scrollable
                    isOpen={patient_modal}
                >
                    <ModalHeader toggle={() => setPatient_modal(!patient_modal)}>
                        Patient Information
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e)=>book_appointment(e)}>
                            <FormGroup>
                                <Label for="patientName">
                                    Patient Name
                                </Label>
                                <Input
                                    id="patientName"
                                    name="patientName"
                                    placeholder="Enter patient name"
                                    type="text"
                                    onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()}
                                    onChange={(e) => setPatient_name(e.target.value) }
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="patientMobNo">
                                    Mobile Number
                                </Label>
                                <Input
                                    id="patientMobNo"
                                    name="patientMobNo"
                                    placeholder="Enter mobile number"
                                    type="text"
                                    maxLength={10}
                                    pattern="[0-9]*"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    value = {patient_mobile}
                                    onChange={(e) => setPatient_mobile(e.target.value) }
                                    required
                                />
                            </FormGroup>
                            <Button size="sm" color="primary" block>Pay Now ( &#8377; {Number(docDtl?.fee).toFixed(2)} )</Button>
                        </Form>
                    </ModalBody>

                </Modal>
                <Login openLogin={loginShow} closeLogin_handler={() => setLoginShow(false)}/>
                {loading && 
                    <LoadingSpinner />
                }
            </>

    
        )
}
export default PickTimeSlot;