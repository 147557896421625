import React, {useEffect, useState} from 'react';
import { notification } from '../../component/hocs/notification';

import axios from 'axios';
import {mainUrl} from '../../component/hocs/mainUrl';
import DocList from './../../component/utility/DocList';
import Footer from '../../component/layout/Footer';

import { FaSearch } from 'react-icons/fa';

import {
    Row,
    Col,
    Input,
    Button,
    ListGroup,
    ListGroupItem,
    Alert,
    Card,
    CardBody,
    Spinner

} from 'reactstrap';


function Search() {
    const [locOpt, setLocOpt] = useState([]);
    const [deptOpt, setDeptOpt] = useState([]);
    const [loc, setLoc] = useState("0");
    const [dept, setDept] = useState("0");
    const [doctorList, setDoctorList] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        axios({
            method: 'post',
            url: mainUrl +'Doctor/all_city',
            headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let list = response.data.map(row => {
                    return(
                        <option value={row.city}> {row.city_name} </option>
                    )
                })
                setLocOpt(list);
            })
            .catch(function (error) {
            console.log(error); 
            });
        
            axios({
            method: 'post',
            url: mainUrl + 'Doctor/all_deparment',
            headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let dept = response.data.map(row => {
                    return(
                        <option value={row.deparment_id}> {row.deparment_name} </option>
                    )
                })
                setDeptOpt(dept);
                if(sessionStorage.getItem('department_id') !== null && sessionStorage.getItem('department_id') !== undefined){
                    setDept(sessionStorage.getItem('department_id'));
                }
            })
            .catch(function (error) {
                console.log(error); 
            });
    }, []);

    const search_click_handler = () => {
        if(loc !== "0" || dept !== "0"){
            setDoctorList([]);
            setLoading(true);
            let postData = {"city_id" : loc, "deparment_id" : dept };
    
            axios.post(mainUrl + 'Admin/doctor_search', postData)
                .then(function (response) {
                    setCount(response.data.length);
                    let doctorList = response.data.map(row => {
                        return(
                            <DocList data={row}/>
                        )
                    });

                    setDoctorList(doctorList);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error); 
                    let noDoctor = <Col><Card><CardBody>No Doctor Found!</CardBody></Card></Col>
                    setDoctorList(noDoctor);
                    setLoading(false);
                });
        }else{
            let notify = notification({ message: "Please Select Location and Department.", type: 'error' });
		    notify();
        }
    }

    return (
        <div>

            <Row className="banner">
                <Col className='p-5 mt-5' sm="6" md="5">
                    <h4 className='text-primary'>Search Doctor</h4>
                    <div className='mb-3'>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            onChange={(e) => setLoc(e.target.value)}
                        >
                            <option value="0"> --Select Location-- </option>
                            {locOpt}
                        </Input>  
                    </div>
                    <div className='mb-3'>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            value= {dept}
                            onChange={(e) => setDept(e.target.value)}
                        >
                            <option value="0"> --Select Category-- </option>
                            {deptOpt}
                        </Input>
                    </div>
                    <div className='text-end mb-3'>
                        <Button color="primary" onClick={search_click_handler}> <FaSearch /> Search</Button>
                    </div>
                </Col>
            </Row>

            <div className='borderBottom'></div>

            <Row className='p-2'>
                <Col xs='12'>
                <Alert color="primary">
                    <p className='m-0'>{count} doctors available.</p>
                    <p className='m-0'>Book appointments with minimum wait-time & verified doctor details</p>
                </Alert>

                </Col>

                <Col md="8" >
                    <Row>
                        {doctorList}
                        {loading && <Col className='text-center'><Spinner color="primary"> Loading... </Spinner></Col>}
                    </Row>
                </Col>
                <Col md="4">
                    <div>
                        
                    </div>
                </Col>


            </Row>

            <Footer />

        </div>
    )
}

export default Search;