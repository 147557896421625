import React from 'react';

import { FaFacebookSquare, FaTwitterSquare, FaWhatsappSquare, FaInstagramSquare} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import {
    Row,
    Col,

} from 'reactstrap';

function Footer() {
    return (
        <div className='footerPage'>
            <Row className='primaryBg p-4 text-light'>
                <Col md="6">
                    <h5> Customer Care :  </h5>
                    <h6><BsFillTelephoneFill size={20} /> +91 7978938108</h6>
                    <h6><MdEmail size={20} /> doortodoctor@gmail.com</h6>
                    <small> We provide customer support. Please feel free to contact us. </small>
                    

                </Col>
                <Col md="6" className='text-center'>
                    <h6 className='m-0 mt-4'>Social Media Platform</h6>
                    <div className='socialIcon'>
                        <a href=" https://www.facebook.com/profile.php?id=100082055852723" target="_blank" rel="noopener noreferrer"><FaFacebookSquare className='me-2'/></a>
                        <a href="https://twitter.com/DoctorDoorto" target="_blank" rel="noopener noreferrer"><FaTwitterSquare className='me-2' /></a>
                        <a href="https://www.instagram.com/doortodoctor" target="_blank" rel="noopener noreferrer"><FaInstagramSquare className='me-2' /></a>
                        <a href="https://wa.me/7978938108" target="_blank" rel="noopener noreferrer"><FaWhatsappSquare className='me-2' /></a>

                    </div>
                </Col>
            </Row>
            
        </div>
    )
}

export default Footer;