import React from 'react';
import slide_1 from './../../assets/img/slide_1.png';
import slide_2 from './../../assets/img/slide_2.png';
import {
    UncontrolledCarousel,

} from 'reactstrap';


function Slider() {
    return (
        <UncontrolledCarousel
            items={[
                {
                altText: 'Slide 1',
                key: 1,
                src: slide_1
                },
                {
                altText: 'Slide 2',
                key: 2,
                src: slide_2
                },
            ]}
            />
    )

}
export default Slider;