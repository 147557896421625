import React, {useState, useEffect} from 'react';

import { notification } from '../hocs/notification';
import Login from './Login';
import {Link} from 'react-router-dom';
import Logo from './../../assets/img/logo.png'; 
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import {FaUserAlt} from "react-icons/fa";


function Header() {
    const [loginShow, setLoginShow] = useState(false);
    const [menuShow, setMenuShow] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [mobileNo, setMobileNo] = useState();

    useEffect(() => {
        
        if( localStorage.getItem('loggedIn') === 'true' ){
            setIsLogin(true);
            let loginDetails = JSON.parse(localStorage.getItem('loginDetails'));
            setMobileNo(loginDetails.mobile_number);
        }else{
            setIsLogin(false); 
        }
    },[isLogin]);

    const logout_click_handler = () => {
        console.log("in")
        let notify = notification({ message: "You logout successfully.", type: 'success' });
		notify();
        localStorage.clear(); 
        sessionStorage.clear(); 
        setIsLogin(false);
    }  

    const closeLogin_handler = () => {
        setLoginShow(false);
        if( localStorage.getItem('loggedIn') === 'true' ){
            setIsLogin(true);
        }else{
            setIsLogin(false); 
        }
    }

    return (
        <div className='headerPage'>
            <Navbar
                className='headerNav borderB'
                color="light"
                expand="lg"
                fixed="top"
                light
            >
                <NavbarBrand href="/">
                    <img className='pe-2' width="75px" src={Logo} alt="logo" />
                    <b className='h4 text-primary'>DOOR TO DOCTOR</b>
                </NavbarBrand>
                <NavbarToggler onClick={() => { setMenuShow(!menuShow) }} />
                <Collapse navbar isOpen={menuShow}>
                    <Nav
                        className="ms-auto"
                        navbar
                    >
                        <NavItem>
                            <Link to="/">
                                <NavLink> HOME </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/wip/">
                                <NavLink> DEPARTMENTS </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/wip/">
                                <NavLink> DOCTORS </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/wip/">
                                <NavLink> CONTACT </NavLink>
                            </Link>
                        </NavItem>

                        
                        <UncontrolledDropdown
                            inNavbar
                            nav
                            >
                            <DropdownToggle
                                caret
                                nav
                            >
                                <FaUserAlt />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem className={isLogin ? 'd-block' : 'd-none'}>
                                    <Link style={{fontWeight: 400}} to="/appointmentList/">My Appointments</Link>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem className={isLogin ? 'd-none' : 'd-block'} 
                                    onClick={() => setLoginShow(true)}>
                                    LOGIN
                                </DropdownItem>
                                <DropdownItem className={isLogin ? 'd-block' : 'd-none'}
                                    onClick={ logout_click_handler}>
                                    LOGOUT
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {isLogin && <span className="text-primary" style={{position: 'absolute',right: '15px', bottom: '5px'}}><b>Hi {mobileNo}</b></span>}

                        
                    </Nav>
                    
                </Collapse>
            </Navbar>
            <Login openLogin={loginShow} closeLogin_handler={() => closeLogin_handler() }/>
        </div>
    )
}

export default Header;