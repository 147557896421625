import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { imageUrl } from '../hocs/mainUrl';
import { MdNoteAlt } from "react-icons/md";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Badge,

} from 'reactstrap';

import { FaThumbsUp } from 'react-icons/fa';
import { SiGooglemaps } from 'react-icons/si';

function DocList({data, ...props}) {
    const navigate = useNavigate();
    const [mapSrc, setMapSrc] = useState('');

    const gotoAppointmentPage = () => {
        sessionStorage.setItem('doc_id', data.doctor_master_id);
        sessionStorage.setItem('department_id', data.deperment_id); 
        navigate('/appointment', { state: { rebooking: false, doc_id: data.doctor_master_id, booking_id: 0, } });
    
        
    }
    useEffect(()=>{
       let map_src = `https://maps.google.com/?q=${data.latitude},${data.longitude}`;
       setMapSrc(map_src);
    },[])
    return (
        <Col md={6}>
            <Card className='mb-2'>
                <CardBody>
                    <Row>
                        <Col xs="2">
                            {data.image_id === "" ?

                                <img className='img-fluid rounded-circle' src={require("../../assets/img/doc_d.png")} alt="Img" />
                                :
                                <img className='img-fluid rounded-circle' src={imageUrl + data.image_id + ".png"} alt="Img" />
                            }

                        </Col>
                        <Col xs="10 mb-2">
                            <h5 color='primary'>{data.name}</h5>
                            <p className='m-0'>{data.degree}</p>
                            <p className='m-0'><b>Department:</b> {data.deparment_name}</p>
                            <p className='m-0'><b>Address:</b> {data.address}</p>
                            <div>
                                <SiGooglemaps size={25} color="#00a500" />
                                <a className='map ms-1' target="_blank" href={mapSrc} >See in Google Map</a>
                            </div>
                            <p className='m-0'>&#8377; {data.fee} Consultation fee at clinic</p>

                            <div className="borderDashed mt-1 mb-1"></div>

                            <Badge className='' color="success">
                                <div className='d-flex ' >
                                    <FaThumbsUp /> <span className='ps-1' >90%</span>
                                </div>
                            </Badge>
                            <small className='ps-2'>(636 votes)</small>
                        </Col>
                        <Col xs="12" >
                            <Button size="sm" block color='primary' onClick={gotoAppointmentPage}> <MdNoteAlt /> Book Appointment</Button>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </Col>
    )

}
export default DocList;