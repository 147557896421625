import React, { useState, useEffect } from 'react';

import { Alert } from 'reactstrap';


function PickTimeSlot({data, select, ...restProps }) {
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        setSelectedIndex(null);
    },[data]);
    
    let getSelected = (t) => {

        setSelectedIndex(t.slot_id);
        select(t.slot_id);
    }  
    
    return (
        
        <div className='timeSlotPicker'>
            {
                (data?.length > 0 && data[0].slot_id !== undefined ) ? data.map((row, index) => {
                    return(
                        <div key={index} className={selectedIndex === row.slot_id ? 'timeSlot active' : 'timeSlot'} onClick={() => getSelected(row)} >{row.slot}</div>
                    )
                })

                :

                    <Alert color="danger">
                        Slot not Available!
                    </Alert>
            }
        </div>
    )
}
export default PickTimeSlot;